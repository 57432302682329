import $ from 'jquery'

let NProgress = window.NProgress // loaded through sprockets, move to vite later

let disabled = null
// One way to disable Nprogress is by passing globals: false when you call $.ajax.
// However, that also disables *all* global events. Which in some cases is not desirable/possible
export const disableNProgressBarTemporarily = () => {
  disabled = true
  setTimeout(() => (disabled = false), 30)
}

if (NProgress) {
  NProgress.configure({ showSpinner: false, minimum: 0.2 })
  $(document).on('page:fetch', () => NProgress.start())
  $(document).on('page:change', () => NProgress.done())
  $(document).on('page:restore', () => NProgress.remove())
  $(document).on('click', '[data-behavior=show-nprogress]', () =>
    NProgress.start()
  )
  $(document).ajaxStart(function (e) {
    if (document.body?.style != null && !disabled) {
      return NProgress.start()
    }
  })
  $(document).ajaxStop(function () {
    if (document.body?.style != null) {
      return NProgress.done()
    }
  })
  window.Turbolinks?.ProgressBar?.disable()
}
